import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const Page2 = ({ data }) => {
  const images = data.allFile.edges;
  console.log(images);
  const [imgsLoaded, setImgsLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (image) => {
      console.log(image.src);
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.src;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.src);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(
      images.map((prop) => {
        const image = prop.node.childImageSharp.fluid;
        return loadImage(image);
      })
    )
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  return (
    <main className="images">
      {imgsLoaded ? (
        images.map((prop, i) => {
          const image = getImage(prop.node.childImageSharp);
          return (
            <GatsbyImage
              image={image}
              alt="test image"
              key={i}
              loading="eager"
            />
          );
        })
      ) : (
        <h1>Loading images...</h1>
      )}
    </main>
  );
};

export default Page2;

export const portfolioQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData
            fluid {
              srcSet
              src
            }
          }
        }
      }
    }
  }
`;
